import React from 'react';

const Credits = () => (
  <small>
    With 💙 from <a href="https://twerlo.com">Twerlo</a>
    <br />
    {' '}
    <a href="https://twerlo.com/privacy-policy/">
      Privacy Policy
    </a>
  </small>
);

export default Credits;
